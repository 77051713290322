:root {
  --black:#2b2d3d;
  --ink:#171725;
  --blue: #1e90ff;
  --sky-blue:#48AFC7;
  --dark-blue:hsl(228, 87%, 30%);
  --white: #ffffff;
  --light-white:#F0F2F4;
  --dark-grey:#696974;
  --light-grey:#9599B6;  
  --white-grey:#CCCEDB;
  --bright-red:#FC5A5A;
  --dark-red:#914146;
  --pink:#D79BAB;
  --jade:#3DD598;
  --orange:#F5D18F;
  --source-sanspro-regular:SourceSansPro-Regular;
  --source-sanspro-bold:SourceSansPro-Bold;
  --source-sanspro-semibold:SourceSansPro-SemiBold;
  --stix-general-bold:STIXGeneralBol;
}
.list-group-item { 
  border: 0px solid rgba(0, 0, 0, 0.125) !important;
}
.dropdown-icon-container{
  height: 36px;
  cursor: pointer;
  background-color: #FFFFFF;
  border-color: #CCCEDB;
  border-left-color: #FFFFFF;
}
.fnol_text{
  font-size: 12px;
  font-family:var(--source-sanspro-bold) ;
  color:#ffffff ;
}
.page-item.active .page-link {
  z-index: 1 !important;
  color:#313639 !important;
  font-weight: bold !important;
}
.dropdown-icon{
  background: url("/static/images/Dropdown.png") no-repeat;
  background-position: 92% 14px;
  /* background-position: 88% 14px; */
  background-size: 10px 6px;
}
.white-background-color{
  background-color: #FAFAFB;
}
.Source-SansPro-Regular{
  font-family: var(--source-sanspro-regular);
}
.Source-SansPro-Bold{
  font-family: var(--source-sanspro-bold);
}
.Source-SansPro-SemiBold{
  font-family:var(--source-sanspro-semibold) ;
}
.STIX-General-Bold{
  font-family: var(--stix-general-bold);
}
.vertical-align{
  vertical-align: center;
}
.top-vertical-align{
  vertical-align: top;
}
.middle-vertical-align{
  vertical-align: middle;
}
.height-35{
  height: 35px;
}
.height-36{
  height: 36px;
}
.height-100
{
  height: 100%;
}
.height-100px{
  height: 100px;
}

.height-400px
{
  height: 400px;
}
.height-700px
{
  height: 700px;
}
.width-100
{
  width: 100%;
}
.font-semi {
  font-weight: 600;
}

.font-medium {
  font-weight: 500;
}

.black-font {
  color: #2b2d3d;
}
.font-size-11 {
  font-size: 11px;
}
.font-size-12 {
  font-size: 12px;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-18 {
  font-size: 18px;
}
.font-size-22 {
  font-size: 22px;
}
.font-size-28 {
  font-size: 28px;
}
.margin-top-10
{
  margin-top: 10px;
}
.margin-top-20
{
  margin-top: 20px;
}
.margin-top-30
{
  margin-top: 30px;
}
.margin-bottom-10
{
  margin-bottom: 10px;
}
.margin-bottom-20
{
  margin-bottom: 20px;
}
.padding-5px{
  padding: 5px;
}
.body-padding{
  padding-top: 80px;
  height: 100%;
}
.body-content{
  padding: 12px; 
  height: 100vh;
  margin-top: 10px;
}
/* top  */
.top-navbar-section {
  background-color: var(--white);
  height: 70px;
}
/*  */
.logo-web{
  height: 34px;
  margin-left: 30px;
  margin-right: 27px;
}
.nav-item-dashboard{
  cursor: pointer;
  width: 95px;
  margin-right: 60px;
}
.nav-link-item{
  color: #212529;
  padding-top: 27px;
  font-size: 13px;
  font-family: var(--source-sanspro-regular);
}
.nav-item-cases{
  cursor: pointer;
  width: 70px;
  margin-right: 40px;
}
.nav-item-users{
  cursor: pointer;
  width: 80px;
  margin-right: 40px;
}
.active-item{
  color:var(--dark-blue);
  border-bottom: 3px solid var(--dark-blue);
}
.non-active-item
{
  color:var(--black);;
}
.profile-nickname{
  font-family: var(--source-sanspro-regular);
  font-size: 12px;
  color: var(--ink);
}
.partner-name{
  font-family: var(--source-sanspro-semibold);
  font-size: 12px;
  color: var(--dark-grey);
}
.partner-logo{
 width: 30px;
 margin-left: -7px;
}
.partnerid-logo{
  height:52px;
  width:52px;
  margin-left: -11px; 
 }
.partnerid-name{
  color: var(--ink);
  font-family: var(--source-sanspro-semibold);
  font-size: 24px;
  margin-right: 8px;
 }
.partnerid-program{
  color:var(--light-grey);
  font-family: var(--source-sanspro-semibold);
  font-size: 14px; 
 }
.partnerId1-name{
  margin-left: -10px; 
  width: 150px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-image{
  margin-left: 20px;
  width: 10px;
  height: 6px; 
}
.dropdown-image{
  width: 10px; 
  height: 6px;
}
.breadcrumb-active
{
  cursor: pointer;    
  font-family: var(--source-sanspro-bold);
  color: var(--dark-blue); 
  font-size: 13px;
}
.breadcrumb-non-active
{
  cursor: pointer;    
  font-family: var(--source-sanspro-regular);
  color: var(--black); 
  font-size: 13px;
}

.arrow-image{
  cursor: pointer;
  margin-right: 20px;
  margin-left: 7px;
}
.calendar-image{
  height:20px;
  width:20px;
  margin-left: 5px;
  margin-right: 10px; 
  margin-top: 7px;
}
.dashboard-content-section
{
  margin-top: -20px;
  margin-right: 26px;
  margin-left: 26px; 
}
.show-title{
  margin-right: 8px;
  margin-top: 9px;
  color: var(--light-grey);
  font-family: var(--source-sanspro-semibold);
  font-size: 12px;
}
.loading-container{
  width: 44px; 
  border: 1px solid var(--white-grey);
  background-color: var(--white);
  border-left-color: var(--white);
}
.downloading-button{
  text-align: right;
  height: 36px;
  color: blue;
  background-color: white;
  font-size: 12px;
  width: 180px;
}
.loading-button{
  height: 20px;
  background-color: var(--white);
  font-size: 14px;
  width: 20px;
}
.unloading-container{
  height: 36px;
  margin-left: -3px;
  border: 1px solid var(--white-grey);
  background-color: var(--white);
  border-left-color: var(--white);
}
.widgets-content-section{
  margin-top: 25px;
  margin-left: 20px;
  margin-right: 20px;
}
.widget-card{
  height: 60px;
  width: 60px;
}
.widget-number{
  color:var(--ink); 
  font-family: var(--stix-general-bold);
  font-size: 32px; 
}
.widget-title{
  color:var(--light-grey); 
  font-family: var(--source-sanspro-semibold);
  font-size: 12px;
  
}
#TotalCases:hover{
  background-color: var(--light-white);
}
#OpenedCases:hover{
  background-color:var(--light-white);
}
#TotalUnverifiedCases:hover{
  background-color: var(--light-white);
}
#CancelledCases:hover{
  background-color:var(--light-white);
}
.cards-content-section{
  margin-left: 20px; 
  margin-right: 20px; 
  margin-bottom: 20px;
}
.cases-card
{
  border: 1px solid var(--white-grey);
  height: 298px;
}
.total-case{
  margin-top: -30px; 
  text-align: center; 
  color: var(--ink); 
  font-family: var(--stix-general-bold);
  font-size: 28px; 
}
.verified-cases{
  text-align: center; 
  color: var(--light-grey);
  font-family: var(--source-sanspro-bold);
  font-size : 14px;
  margin-top: 10px;
}
.service-card
{
  border: 1px solid var(--white-grey);
  height: 265px;
}
.servicelevel{
  color: var(--bright-red);
  font-family: var(--stix-general-bold);
  font-size: 28px;
}
.serviceLevel74
{
  color: var(--dark-red);
  font-family: var(--stix-general-bold);
  font-size: 28px;
}
.serviceLevel80
{
  color:var(--orange);
  font-family: var(--stix-general-bold);
  font-size: 28px;
}
.serviceLevel81
{
  color:var(--sky-blue);
  font-family: var(--stix-general-bold);
  font-size: 28px;
}
.service-image{
  width:20px; 
  height:19px;
}
.compliance-rate{
  color:var(--light-grey);
  font-family: var(--source-sanspro-regular);
  font-size: 12px;
}
.abandonrates
{
  color:var(--bright-red);
  font-family: var(--stix-general-bold);
  font-size: 28px;
}
.abandonrates4{
  color: var(--sky-blue); 
  font-family: var(--stix-general-bold);
  font-size: 28px;
}
.abandonrates7{
  color: var(--orange); 
  font-family: var(--stix-general-bold);
  font-size: 28px;
}
.abandonrates8{
  color: var(--dark-red); 
  font-family: var(--stix-general-bold);
  font-size: 28px;
}
.total-call-offer{
  margin-top: 4px;
  color: var(--light-grey);
  font-family: var(--source-sanspro-bold);
  font-size: 12px;
}
.category-card
{
  border: 1px solid var(--white-grey);
  height: 580px;
}
.card-header-background{
  background-color: var(--light-white);
}
.card-header-title{
  color: var(--ink);
  font-family: var(--stix-general-bold);
  font-size: 16px;
}
.category-chart{
  max-height: 350px;
  min-height: 250px;
}
.category_thead-background{
  background-color: #FAFAFB;
  border-radius: 10px;
}
.category_tr{
  color:var(--light-grey);
  font-family: var(--source-sanspro-bold);
  font-size: 10px;
}
.accident-indication{
  margin-right: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--orange);
  display: inline-block; 
}
.breakdown-indication{
  margin-right: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color:var(--dark-blue);
  display: inline-block; 
}
.enquiry-indication{
  margin-right: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #60ADC3;
  display: inline-block; 
}
.others-indication{
  margin-right: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--pink);
  display: inline-block; 
}
.case-percent{
  color: var(--black);
  font-family: var(--stix-general-bold);
  font-size: 22px;
}
.case-number{
  color: var(--black);
  font-family: var(--source-sanspro-bold);
  font-size: 12px;
}
.offered-card
{
  border: 1px solid var(--white-grey);
}
.total-services-number
{
  color: #171725;
  font-family: var(--stix-general-bold);
  font-size: 28px;
}
.location-card
{
  border: 1px solid var(--white-grey);
  height: 442px;
}
.location_thead-background{
  background-color: #B5D0EE;
  border-radius: 10px;
  border-bottom: 0px ;
}
.location_thead{
  color: var(--black);
  font-family: var(--source-sanspro-semibold);
  font-size: 11px;
}
.location_tr{
  color: var(--ink);
  font-family: var(--source-sanspro-regular);
  font-size: 12px;
}
.location-table-content
{
  max-height: 330px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.performance-card
{
  border: 1px solid var(--white-grey);
  height: 580px;
}
.performance-table{
  table-layout: fixed;
  margin-bottom: 20px;
}
.garage-content
{
  padding-left: 12px; 
  width: 25%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.destination-content{
  padding-left: 12px; 
  width: 35%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.accidentcase-content{
  padding-left: 14px;
  width: 20% ;
}
.breakcase-content{
  padding-left: 17px;
  width: 20% ;
}
/* SLA Report Style */
.sla-modal-title{
  margin-left: 530px;
  color: var(--black);
  font-family: var(--source-sanspro-regular);
  font-size: 21px;
}
.sla-logo-container{
  margin-left: 20px;
  margin-top: 14px;
}
.sla-logo-image
{
  height:60px; 
  width:60px;
}
.sla-partnerid-name{
  margin-top: 14px;
  color:var(--ink);
  font-family: var(--source-sanspro-regular);
  font-weight: bold;
  font-size: 14px;
}
.sla-total-calloffered{
  margin-bottom: 4px;
  color: var(--black);
  font-weight: bold;
  font-family: var(--source-sanspro-bold);
  font-size: 14px;
}
.sla-varianty{
  color: var(--light-grey);
  font-family:var(--source-sanspro-bold);
  font-size: 12px; 
}
.sla-call-height{
  height: 64px; 
}
.sla-call-title{
  margin-bottom: 20px;
  color: var(--black);
  font-family: var(--source-sanspro-bold);
  font-size: 18px;
}
.sla-call-subtitle{
  margin-bottom: 20px;
  color: var(--light-grey);
  font-family: var(--source-sanspro-bold);
  font-size: 12px;
}
.sla-small-icon{
  height: 16px;
  width: 16px;
  margin-top: 6px;
}
.sla-calloffered-title_current{
  margin-top: 30px;
  margin-bottom: 20px;
  color:var(--ink);
  font-family: var(--stix-general-bold);
  font-size: 18px;
}
.sla-calloffered-th_current{
  padding: 8px;
  background-color: var(--dark-blue);
  color: var(--white);
  font-family: var(--source-sanspro-semibold);
  font-size: 11px;
}
.sla-calloffered-title_last{
  margin-top: 30px;
  margin-bottom: 20px;
  color:var(--dark-grey);
  font-family: var(--stix-general-bold);
  font-size: 18px;
}
.sla-calloffered-th_last{
  padding: 8px;
  background-color: var(--white);
  color: var(--black);
  font-family: var(--source-sanspro-semibold);
  font-size: 11px;
} 
.sla-black-content
{
  margin-bottom: 5px;
  color: var(--black);
  font-family: var(--source-sanspro-regular);
  font-size: 12px;
}
.sla-callanswer-content
{
  margin-bottom: 20px;
  color: var(--dark-blue);
  font-family: var(--source-sanspro-bold);
  font-size: 16px;
}
.sla-callanswerin20s-content
{
  margin-bottom: 20px;
  color: var(--black);
  font-family: var(--source-sanspro-regular);
  font-size: 12px;
}
.sla-callanswerafter20s-content
{
  color:var(--black);
  font-family: var(--source-sanspro-regular);
  font-size: 12px;
}
.sla-callabandonin20s-content
{
  margin-bottom:2px;
  color: var(--black);
  font-family:var(--source-sanspro-regular);
  font-size: 12px;
}
.sla-totalcalloffered-last
{
  margin-bottom: 24px;
  color: var(--bright-red);
  font-family: var(--source-sanspro-regular); 
  font-size: 12px;
}
.sla-activity_image{
  margin-bottom: 2px;
  height:10px; 
  width:10px;
}
.sla-chart{
  margin-left: 20px;
  margin-right: 20px;
  border-color: var(--dark-grey);
}
.sla-chart-title{
  color: var(--black);
  font-family: var(--source-sanspro-bold);
  font-size: 18px;
}
.sla-chart-indicator{
  color: var(--light-grey);
  font-family: var(--source-sanspro-bold);
  font-size: 12px;
}
.sla-chart-currentyear{
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--dark-blue);
  display: inline-block;
  margin-right: 10px;
}
.sla-currentyear-indicator{
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--dark-blue);
  display: inline-block;
  margin-right: 10px;
}
.sla-target-indicator{
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--jade);
  display: inline-block;
  margin-right: 10px;
}
/* Cases Style */
.btn-confirm{
  height: 45px;
  background-color:var(--white);
  border-color: var(--dark-blue);
  color: var(--dark-blue);
  border-radius: 30px;
  width: 150px;
  font-family: Lato-Regular;
  font-size: 18px;
}
.btn-reject{
  height: 45px;
  background-color: var(--dark-blue);
  color: var(--white);
  border-radius: 30px;
  width: 150px;
  font-family: Lato-Regular;
  font-size: 18px;
}

.btn-reject:hover{
  color: var(--white);
}

.btn-export{
  text-align: left;
  height: 36px;
  color: #FFFFFF;
  background-color: #00008F;
  font-size: 12px;
  width: 180px;
}
.allcase_input{
  width: 80px;
  height: 36px;
  color: var(--black); 
  font-weight: bold;
  border: 1px solid #CCCEDB;   
  font-family: var(--source-sanspro-regular);
  font-size: 12px;
}
.search_input{
  height: 36px;
  border: 1px solid #CCCEDB; 
  font-weight: bold; 
  border-right-color: #FFFFFF;
  font-size: 12px;
}
.calendar_input{
  width: 140px;
  height: 36px;
  color: #2B2D3D;
  font-weight: bold;
  border: 1px solid #CCCEDB;
  border-right-color: #FFFFFF;
  font-family: var(--source-sanspro-regular);
  font-size: 12px;
  border-top-right-radius: 0px;
  border-bottom-right-radius:0px ;
}
.calendar-container{
  height: 36px;
  margin-left: -3px;
  border: 1px solid #CCCEDB;
  background-color: #FFFFFF;
  border-left-color: #FFFFFF;
}
.list-case-item
{
  font-weight: bold;
  color: #2B2D3D;
  font-family: var(--source-sanspro-regular);
  font-size: 12px;
}
.input-divider{
  cursor: pointer;
  background-color: #FFFFFF;
  border-color: #CCCEDB;
  border-left-color: #FFFFFF;
}
.angle-down-icon{
  font-size: 18px;
  color: #919191;
}
.case-widgets{
  cursor: pointer;
  height: 100%;
  margin-left: 18px;
  margin-right: 30px;
}
.case-widget-container{
  width: 700px;
  margin-left: 20px;
}
.case-widget
{
  margin-right: 8px;
  height:32px; 
  width:32px;  
}
.case-widget-title{
  font-family: var(--source-sanspro-regular);
  color: #9599B6;
  font-size: 13px;
}
.loading-section{
  margin-top: 120px;
  margin-bottom: 120px;
}
.cases-section{
  cursor: pointer;
  margin-left: 5px;
  margin-right: 20px;
  margin-bottom: 30px;

}
.case-table{
  table-layout: fixed;
  margin-bottom: 20px;
  font-family: var(--source-sanspro-regular);
}
.case-table-header{
  background-color: #B5D0EE;
  text-align: top;
  font-size: 12px;
  color: #2B2D3D;
  font-weight: bold;
  font-family: var(--source-sanspro-regular);
}
.case-table-item{
  color: #000000;
  font-family: var(--source-sanspro-regular);
  font-size: 13px;
}
.case-icon{
  margin-top:10px;
  height:32px;
  width:32px;
  text-align: top;
}
.case-table-cell{
  margin-top: 10px; 
  margin-left: 7px; 
}
.ellipsis-cell{
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.total-page{
  margin-left: 21px; 
  padding-top: 5px; 
  color: #92929D; 
  font-family:var(--source-sanspro-regular);
  font-size-adjust: 16px;
}
.empty-section
{
  margin-left: 20px;
  margin-right: 30px;
  height: 450px;
}
.case-main-infor{
  margin-top: 80px;
  font-weight: bold;
  color: #3B353F;
  font-family: var(--source-sanspro-regular);
  font-size: 18px;
}
.case-sub-infor{
  margin-top: 20px;
  color: #919191; 
  font-family: var(--source-sanspro-regular);
  font-size: 12px; 
}
/* Cases Details Style */
.modal-title-photos{
  margin-left: 310px;
  color: #2B2D3D;
  font-weight: 550;
  font-family: var(--source-sanspro-regular);
  font-size: 21px;
}
.casedetails-client-container
{
  color: #9599B6;
  font-family: var(--source-sanspro-regular);
  font-size: 19px;
  margin-bottom: 10px;
}
.casedetails-clientname{
  margin-left: 10px;
  font-family: var(--source-sanspro-bold);
  font-size: 16px;
}
.casedetails-logoname{
  color: #3B353F; 
  font-family: var(--source-sanspro-regular);
  font-size: 17px;
}
.casedetails-program{
  font-size: 13px;
  font-family: var(--source-sanspro-bold);  
}
.casedetails-status-container{
  color: #9599B6;
  font-family: var(--source-sanspro-regular);
  font-size: 12px;
  margin-top: 2px;
}
.casedetails-status{
  color: #9599B6;
  font-family: var(--source-sanspro-regular);
  font-size: 12px;
}
.casedetails-status-icon{
  margin-right: 10px;
  width: 24px;
  height: 24px;
}
.casedetails-towingcard
{
  cursor: pointer;
  border: 1px solid #CCCEDB;
}
.casedetails-authorize
{
  font-family: var(--source-sanspro-regular);
  margin-left: 34px;
  font-size: 14px;
  margin-bottom: 20px;
}
.casedetails-locationtable{
  border-spacing: 10px;
  margin-bottom: 15px;
  font-family: var(--source-sanspro-regular);
  table-layout: fixed;
}
.casedetails-locationth
{
  vertical-align: top;
  color: #9599B6;
  font-family: var(--source-sanspro-regular);
  font-size: 14px;
  font-weight: bold;
  margin-right: 30px;
}
.casedetails-locationdetails{
  vertical-align: top;
  color: #171725;
  width: 40%;
  font-family: var(--source-sanspro-regular);
  font-size: 14px;
  margin-right: 20px;
}
.casedetails-distance{
  padding-top: 10px;
  vertical-align: top;
  color: #9599B6;
  font-family: var(--source-sanspro-regular);
  font-weight: bold;
  font-size: 14px;
  margin-right: 30px;
}
.casedetails-fnol-container
{  
  margin-top: 20px;
  background-color: #F2F4F7;
}
.street_address{
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.distance-details{
  padding-top: 10px;
  vertical-align: top;
  color: #171725;
  font-family: var(--source-sanspro-regular);
  font-size: 14px;
  margin-right: 20px;
}
.space-td{
  width: 40px;
  margin-right: 32px;

}
.space-td1{
  vertical-align: top;
  width: 30px;
  margin-right: 30px;

}
.space-td2{
  vertical-align: top;
  width: 40px;
  margin-right: 30px;

}
.space-td-destination{
  vertical-align: top;
  width: 40%;
  margin-right: 20px;
}
.towingcard-body
{
  box-shadow: none;
  margin-left: 13px;
  padding-right: 40px;
  margin-top: 5px;
  margin-bottom: 10px;
}
.arrowright-icon{
  height:15px;
  padding-bottom: 5px;
  margin-right: 20px;
}
.towing-authorized-icon{
  padding-bottom: 5px;
  margin-right: 10px;
  height:23px;
  width:23px;
}
.fnol-download-button{
  height: 40px;
  width: 100%;
  background-color: #00008F;
  color: #FFFFFF;
  font-family: var(--source-sanspro-regular);
  font-weight: bold;
  font-size: 12px;
}
.fnol-downloading-button
{
  height: 40px;
  width: 100%;
  background-color: white;
  font-family:  var(--source-sanspro-regular);
  font-size: 16px;
}
.download_white-icon{
  margin-left: 60px;
  height:15px;
  width:15px;
}
.ic_attachment-icon{
  margin-right: 60px;
  height:15px;
  width:15px;
}
.details-icon{
  height:22px;
  padding-bottom: 5px;
  margin-right: 14px ;
}
.case-details-title
{
  color: #171725;
  font-family: var(--stix-general-bold);
  font-size: 14px;
  margin-right: 8px;
}
.case-details-id{
  color: #9599B6; 
  font-family: var(--source-sanspro-bold);
  font-size: 14px;
}
.casedetails-label{
  padding: 5px;
  color: #9599B6;
  font-family: var(--source-sanspro-bold);
  font-size: 12px;
  
}
.casedetails-row{
  margin-top: 10px;
  margin-bottom: 8px;
}
.bold-title{
  margin-left: 25px;
  font-weight: bold;
}
.casedetails-input{
  color: #2B2D3D;
  font-family: var(--source-sanspro-regular);
  font-size: 12px;
  background-color: #F0F2F4;
}
.thumb_fnol2-icon{
  height:132px;
  width:95px;
}
.icon-size32{
  height: 32px;
  width: 32px;
}
.carddetails-container{
  cursor: pointer;
  border: 1px solid #CCCEDB; 
}
.carddetails-body{
  box-shadow: none;
  margin-left: 13px;
  padding-right: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.photos-card-header{
  color: #171725;
  font-family: var(--source-sanspro-bold);
  font-weight: bold;
  font-size: 16px;
}
.photos-icon{
  height: 25px;
  padding-bottom: 5px;
  margin-right: 14px;
}
.photos-seeall{
  color: #171725; 
  font-family: var(--source-sanspro-regular);
  font-weight: bold;
  font-size: 12px;
}
.first-photo{
  border-radius: 10px;
  height: 400px;
}
.second-photo{
  border-radius: 10px;
  height: 192px;
  height:100%;
  width:100%;
}
/*  */
.br-10px{
  border-radius: 10px;
}
.br-22px{
  border-radius: 22px;
}
.white-color{
 color: var(--white);
}
.pt-10px
{
  padding-top: 10px;
}
.pl-12px{
  padding-left: 12px;
}
.mr-5px{
  margin-right: 5px;
}
.mr-10px{
  margin-right: 10px;
}
.mr-20px{
  margin-right: 20px;
}
.mr-28px{
  margin-right: 28px;
}
.ml-5px
{
  margin-left: 5px;
}
.ml-7px
{
  margin-left: 7px;
}
.ml-10px
{
  margin-left: 10px;
}
.ml-20px
{
  margin-left: 20px;
}
.ml-22px
{
  margin-left: 22px;
}
.ml-28px
{
  margin-left: 28px;
}
.ml-30px
{
  margin-left: 30px;
}
.ml-40px
{
  margin-left: 40px;
}
.ml-35px
{
  margin-left: 35px;
}
.ml-70px
{
  margin-left: 70px;
}
.ml-80px
{
  margin-left: 80px;
}
.ml-130px{
  margin-left: 130px;
}
.ml-165px{
  margin-left: 165px;
}
.mlr-10{
  margin-left: 10px;
  margin-right: 10px;
}
.mlr-20{
  margin-left: 20px;
  margin-right: 20px;
}
.mlr{
  margin-left: 30px;
  margin-right: 30px;
}
.mt_minis_4px{
  margin-top: -4px;
}
.mt_minis_5px{
  margin-top: -5px;
}
.mt_minis_40px{
  margin-top: -40px;
}
.mt_minis_52px{
  margin-top: -52px;
}
.mt-0px{
  margin-top: 0px;
}
.mt-2px{
  margin-top: 2px;
}
.mt-3px{
  margin-top: 3px;
}
.mt-4px{
  margin-top: 4px;
}
.mt-5px{
  margin-top: 5px;
}
.mt-7px{
  margin-top: 7px;
}
.mt-8px{
  margin-top: 8px;
}
.mt-10px{
  margin-top: 10px;
}
.mt-12px{
  margin-top: 12px;
}
.mt-15px
{
  margin-top: 15px;
}
.mt-18px
{
  margin-top: 18px;
}
.mt-20px{
  margin-top: 20px;
}
.mt-28px{
  margin-top: 28px;
}
.mt-40px
{
  margin-top: 40px;
}
.mt-30px
{
  margin-top: 30px;
}


.mb-10px
{
  margin-bottom: 10px;
}
.mb-15px
{
  margin-bottom: 15px;
}
.mb-20px
{
  margin-bottom: 20px;
}
.mb-29px
{
  margin-bottom: 29px;
}
.mb-30px
{
  margin-bottom: 30px;
}
/*  */
.searching_icon{
  height: 80px;
  width: 80px;
  margin-top: 6px;
}
.empty_image_message{
  margin-top: 36px;
  margin-bottom: 115px;
  color: #2B2D3D;
  font-family: var(--source-sanspro-regular);
  font-size: 12px;  
}

.searching_image_message{
  margin-top: 36px;
  margin-bottom: 115px;
  color: #9599B6;
  font-family: var(--source-sanspro-regular);
  font-size: 12px;  
}

.bottom-section
{
  border-top: 1px solid var(--white-grey);
  background-color: var(--white);
  height: 58px;
}
.bottom-content{
  font-family: var(--source-sanspro-regular);
  font-size: 12px;
  color: var(--black);
}
.avatar-circle {
  width: 30px;
  height: 30px;
  background-color:var(--dark-blue);
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
}
@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  
  left: 50%;
  top: 55%;
  width: 80px;
  height: 80px;  
  
  border: 16px solid var(--light-white);
  border-radius: 50%;
  border-top: 16px solid var(--dark-blue);
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite;
}
.loader {
  position: absolute;
  left: 50%;
  top: 55%;
  z-index: 1;
  width: 50px;
  height: 50px;
  margin: -75px 0 0 -75px;
  border: 16px solid var(--light-white);
  border-radius: 50%;
  border-top: 16px solid var(--dark-blue);
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.initials {
  position: relative;
  top: 2px; /* 25% of parent */
  font-size: 8x; /* 50% of parent */
  line-height: 15px; /* 50% of parent */
  color:var(--white);  
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
}
.sticktop {
  background:#B5D0EE;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  z-index: 10;


}
table.tableBodyScroll tbody {
  display: block;
  max-height: 401px;
  overflow-y: scroll;
  overflow-x: hidden;
}
table.tableBodyScroll1 tbody {
  display: block;
  max-height: 387px;
  width: 100%;
  overflow-y: scroll;
}

table.tableBodyScroll thead, table.tableBodyScroll tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
table.tableBodyScroll1 thead, table.tableBodyScroll1 tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}


.cropped_vertical {
   /* width of container */  
  height: 100%; /* height of container */
  object-fit: cover; /* contain and cover height of container */
  border-radius: 10px;
  
 }
 .fit_image_container{
  height: 100%; /* height of container */
  object-fit: contain; /* contain and cover height of container */
  border-radius: 10px;
 }
 
 .cropped_hoziontal {
  /* width of container */
 width: 100%; /* height of container */
 height: 100%;
 border-radius: 10px;
 object-fit: cover;
 
}
.textcolor {
  color: red;
}
 
 
.bgImage {
	background-image: url('/BG_large.png');
	background-repeat: no-repeat;
	background-attachment: fixed;
	background-size: 100%;
	background-position: top;
}
/* STIX Fonts */
@font-face {
  font-family: STIXGeneral;
  src: url(FontsPro/STIXFonts/STIXGeneral.otf);
}
@font-face {
  font-family: STIXGeneralBol;
  src: url(FontsPro/STIXFonts/STIXGeneral-Bold.ttf);
}
@font-face {
  font-family: STIXGeneralBolIta;
  src: url(FontsPro/STIXFonts/STIXGeneralBolIta.otf);
}
@font-face {
  font-family: STIXGeneralItalic;
  src: url(FontsPro/STIXFonts/STIXGeneralItalic.otf);
}
/* Front Pro */
@font-face {
  font-family: SourceSansPro-Black;
  src: url(FontsPro/SourceSansPro-Black.ttf);
}
@font-face {
  font-family: SourceSansPro-BlackItalic;
  src: url(FontsPro/SourceSansPro-BlackItalic.ttf);
}
@font-face {
  font-family: SourceSansPro-Bold;
  src: url(FontsPro/SourceSansPro-Bold.ttf);
}
@font-face {
  font-family: SourceSansPro-BoldItalic;
  src: url(FontsPro/SourceSansPro-BoldItalic.ttf);
}
@font-face {
  font-family: SourceSansPro-ExtraLight;
  src: url(FontsPro/SourceSansPro-ExtraLight.ttf);
}
@font-face {
  font-family: SourceSansPro-ExtraLightItalic;
  src: url(FontsPro/SourceSansPro-ExtraLightItalic.ttf);
}
@font-face {
  font-family: SourceSansPro-Italic;
  src: url(FontsPro/SourceSansPro-Italic.ttf);
}
@font-face {
  font-family: SourceSansPro-Light;
  src: url(FontsPro/SourceSansPro-Light.ttf);
}
@font-face {
  font-family: SourceSansPro-LightItalic;
  src: url(FontsPro/SourceSansPro-LightItalic.ttf);
}
@font-face {
  font-family: SourceSansPro-Regular;
  src: url(FontsPro/SourceSansPro-Regular.ttf);
}
@font-face {
  font-family: SourceSansPro-SemiBold;
  src: url(FontsPro/SourceSansPro-SemiBold.ttf);
}
@font-face {
  font-family: SourceSansPro-SemiBoldItalic;
  src: url(FontsPro/SourceSansPro-SemiBoldItalic.ttf);
}
/*  */
@font-face {
  font-family: Lato-Black;
  src: url(LatoFont/Lato-Black.ttf);
}
@font-face {
  font-family: Lato-BlackItalic;
  src: url(LatoFont/Lato-BlackItalic.ttf);
}
@font-face {
  font-family: Lato-Bold;
  src: url(LatoFont/Lato-Bold.ttf);
}
@font-face {
  font-family: Lato-BoldItalic;
  src: url(LatoFont/Lato-BoldItalic.ttf);
}
@font-face {
  font-family: Lato-Hairline;
  src: url(LatoFont/Lato-Hairline.ttf);
}
@font-face {
  font-family: Lato-HairlineItalic;
  src: url(LatoFont/Lato-HairlineItalic.ttf);
}
@font-face {
  font-family: Lato-Heavy;
  src: url(LatoFont/Lato-Heavy.ttf);
}
@font-face {
  font-family: Lato-HeavyItalic;
  src: url(LatoFont/Lato-HeavyItalic.ttf);
}
@font-face {
  font-family: Lato-Italic;
  src: url(LatoFont/Lato-Italic.ttf);
}
@font-face {
  font-family: Lato-Light;
  src: url(LatoFont/Lato-Light.ttf);
}
@font-face {
  font-family: Lato-LightItalic;
  src: url(LatoFont/Lato-LightItalic.ttf);
}
@font-face {
  font-family: Lato-Medium;
  src: url(LatoFont/Lato-Medium.ttf);
}
@font-face {
  font-family: Lato-MediumItalic;
  src: url(LatoFont/Lato-MediumItalic.ttf);
}
@font-face {
  font-family: Lato-Regular;
  src: url(LatoFont/Lato-Regular.ttf);
}
@font-face {
  font-family: Lato-Semibold;
  src: url(LatoFont/Lato-Semibold.ttf);
}
@font-face {
  font-family: Lato-SemiboldItalic;
  src: url(LatoFont/Lato-SemiboldItalic.ttf);
}
@font-face {
  font-family: Lato-Thin;
  src: url(LatoFont/Lato-Thin.ttf);
}
* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  border: none;
  outline: none; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-text-size-adjust: none;
  letter-spacing: inherit;
  font-family: inherit;
}

.card_shadow_0{
box-shadow:none;
}
.card_border_1{
border: 1px solid #ddd;
}

.edit_hover_class a {
visibility:hidden;
}
.edit_hover_class:hover a {
visibility:visible;
}

@keyframes fadeIn {
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
}

::-webkit-scrollbar {
  width: 8px; 
  height: 8px; 
}
::-webkit-scrollbar-button {
  background: unset;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);   
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgb(235, 235, 235);   
}​
::-webkit-scrollbar-thumb:window-inactive {
  background: rgb(235, 235, 235); 
}
.refresh_sec{
animation: fadeIn 0.5s linear;	
}

.hidden {
  display: none;
}

.card {

box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, .15);
}
.switch {
position: relative;
display: inline-block;
width: 36px;
height: 20px;
}

.switch input { 
opacity: 0;
width: 0;
height: 0;
}

.slider {
position: absolute;
cursor: pointer;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: #ccc;
-webkit-transition: .4s;
transition: .4s;
}

.slider:before {
position: absolute;
content: "";
height: 16px;
width: 16px;
left: 2px;
bottom: 2px;
background-color: white;
-webkit-transition: .4s;
transition: .4s;
}

input:checked + .slider {
background-color: #007bff;
}

input:focus + .slider {
box-shadow: 0 0 1px #007bff;
}

input:checked + .slider:before {
-webkit-transform: translateX(16px);
-ms-transform: translateX(16px);
transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
border-radius: 34px;
}

.slider.round:before {
border-radius: 50%;
}

.istudio_logo {
margin-left:60px; margin-right:14px; max-height:34px;
}

.istudio_top_navibar{
margin-right:50px; margin-top:10px;
}

.istudio_support_link{
font-size:11pt; margin-top:3px; margin-right:-10px;
}

.istudio_user_img_s{
width:28px;
}

.istudio_user_img_m{
width:40px;
}

.istudio_menu_navibar{
background:#f8fbff; height:43px;
}

.istudio_hoz_menu{
margin-right:50px;
}

.istudio_hoz_menu_active{
font-weight:bold;
color:#4c4c4c;
}

.istudio_ver_menu_0{
padding:7px 20px 7px 20px; border-radius:10px; width:100%; text-align:left;
}
.istudio_ver_menu_1{
padding:7px 20px 7px 38px; border: 0px; border-radius:10px; width:100%; text-align:left;
}
.istudio_ver_menu_2{
padding:14px; padding-left:30px; border: 0px; border-radius:10px; width:100%; text-align:left;
}
.istudio_ver_menu_active{
background-color: #f1f2f2;
font-weight:bold;
}

.client_logo{
margin-left:50px; padding:3px 20px 3px 20px;
}

.client_logo_img{
width:30px; margin-top:-2px; margin-right:-10px;
}

.dashboard_widget_title{
margin-top:-10px; margin-bottom:15px;
}

.ver_border_right{
border-right: 1px solid #ddd;
}

.ver_noborder{
border-right: 0px solid #ddd;
}

.composer_img{
height:130px; border-radius:11px 11px 0px 0px;
}

.composer_img_upload{
opacity: 0.0; position: absolute; top: 0; left: 0; bottom: 0; right: 0; width: 100%; height:50%;
}

.composer_text{
font-size:10pt; height:40px; border-radius:0px; border:0px; border-bottom: 1px solid #ddd;
}

.composer_select{
font-size:10pt; height:40px; border-radius:0px 0px 11px 11px; border:0px;
}

.composer_area{
max-height:485px; overflow: auto; overflow-x:hidden;
}

.composer_textarea{
font-size:10pt; min-height:250px; border-radius:11px 11px 0px 0px; border:0px; border-bottom: 1px solid #ddd;
}
.composer_input_text{
font-size:10pt; height:40px; border-radius:0px; border:0px; border-bottom: 1px solid #ddd;
}
.composer_select{
font-size:10pt; height:40px; border-radius:6px; border:0px;
}

.save_label{
padding-top:30px; padding-right:10px;	
}

.action_button{
height:32px; margin:0px 8px 0px 8px; border: 2px solid #007bff; border-radius:5px; width:100px;
}

.action_to_label{
font-size:7pt; font-weight:bold;
}

.action_nav_box{
box-shadow:none; border: 2px solid #ddd; width:348px;
}

.action_nav_list{
font-size:7pt; font-weight:bold; margin-left:0px;
}

.action_nav_box_item{
width:82px;
}

.box_shadow_none{
box-shadow:none; border: 1px solid #ddd;
}

.right_option_panel{
max-height:600px; overflow: auto; overflow-x:hidden;
}

.option_panel_div{
padding-bottom:5px;
}

.option_panel_item_label{
font-size:9pt;
}

.option_panel_item_radio{
margin-top:6px;
}

.option_panel_item_btn{
font-size:9pt; margin-top:4px; padding:7px; border: 1px solid #aaa; border-radius:5px;
}

.input_noborder{
border:0px;
font-size:12pt;
padding:5px;
margin:5px 15px;
}

.transaction_popup{
padding:20px; height:520px; overflow: auto; overflow-x:hidden;
}


/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



